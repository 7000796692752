export default [
    {
        name: 'Synthese',
        path: 'Demande/Synthese'
    },
    {
        name: 'Progression',
        path: 'DerogBio/Demande/Progression.aspx'
    },
    {
        name: 'Avis',
        path: 'DerogBio/Demande/Avis.aspx'
    },
    {
        name: 'ReadRequest',
        path: 'DerogBio/Demande/Read.aspx'
    },
    {
        name: 'EditContact',
        path: 'DerogBio/Contact/Edit.aspx'
    },
    {
        name: 'AdditionalInformationRequest',
        path: 'DerogBio/Demande/AdditionalInformationRequest.aspx'
    }
];

const InfoArticle = () => import('@/Demande/Components/InfoArticleComponent.vue');
const ArticleChoice = () => import('@/Demande/Components/ArticleChoice.vue');
const Synthese = () => import('@/Demande/Components/Synthese.vue');
const AddDocuments = () => import('@/Demande/Components/AddDocumentsComponent.vue');
const Confirmation = () => import('@/Demande/Components/ConfirmationComponent.vue');
const ArticleLoader = () => import('@/Demande/Components/ArticleLoaderComponent.vue');
const ListDemande = () => import('@/Demande/Components/ListDemandeComponent.vue');

export const VueRoutes = [
    
    {
        name: 'InfoArticle',
        path: '/InfoArticle',
        component: InfoArticle,
        // Below here we are providing the codeArticle just for the time being until we link the pages, after that we would get the code article
        props: (route) => ({ codeArticle: route.query.codeArticle, encryptedOperatorId: route.query.operatorId })
    },
    {
        name: 'ArticleChoice',
        path: '/ArticleChoice',
        component: ArticleChoice,
        props: (route) => ({ encryptedOperatorId: route.query.operatorId })
    },
    {
        name: 'ArticleLoader',
        path: '/ArticleLoader',
        component: ArticleLoader,
        props: (route) => ({ requestGuid: route.query.requestGuid, encryptedOperatorId: route.query.operatorId, isSve: true, codeArticle: route.query.codeArticle })
    },
    {
        name: 'Synthese',
        path: '/Synthese',
        component: Synthese,
        props: (route) => ({ encryptedOperatorId: route.query.operatorId, requestGuid: route.query.requestGuid, isSve: true })
    },
    {
        name: 'PublicSynthese',
        path: '/PublicSynthese',
        component: Synthese,
        props: (route) => ({ encryptedOperatorId: route.query.operatorId, requestGuid: route.query.requestGuid, isSve: true }),
        meta: {
            allowAnonymous: true
        }
    },
    {
        name: 'AddDocuments',
        path: '/AddDocuments',
        component: AddDocuments,
        props: (route) => ({ encryptedOperatorId: route.query.operatorId, requestGuid: route.query.requestGuid})
    },
    {
        name: 'Confirmation',
        path: '/Confirmation',
        component: Confirmation,
        props: (route) => ({ encryptedOperatorId: route.query.operatorId, requestGuid: route.query.requestGuid })
    },
    {
        name: 'ListDemande',
        path: '/ListDemande',
        component: ListDemande,
        props: (route) => ({ encryptedOperatorId: route.query.operatorId })
    }
];