import Axios from '@/Common/Services/axios';
import ReferentialService from '@/Referential/Services/referential-service';

var CurrentUserLoader = null;

function getCurrentUserPromise() {
    if (!CurrentUserLoader) {
        CurrentUserLoader = new Promise(function (resolve) {
            Axios.get('Users/GetCurrentUser').then(response => {
                resolve(response);
            });
        })
    }
    return CurrentUserLoader;
}

export default {
	getById(id) {
		return Axios.get('Users/GetUser?Id=' + id).then(response => response);
	},
    async getCurrentUser() {
        let currentUser = sessionStorage.getItem('currentUser');
        if (currentUser !== null) {
            currentUser = JSON.parse(currentUser);
        }

        else {
            const currentUserLoaded = await getCurrentUserPromise();
            currentUser = currentUserLoaded.data.user;
            sessionStorage.setItem('currentUser', JSON.stringify(currentUser));
        }
        return currentUser;
    },
    getUserList() {
        return Axios.get("Users/GetUsers").then(response => {
            return response.data.model;
        });
    },
    getCurrentUserCulture() {
        return this.getCurrentUser().then(currentUser => {
            let user = currentUser.data.general;
            if (user && user.culture) {
                localStorage.setItem('culture', user.culture);
                return user.culture;
            }
            return null;
        });
    },
	getCultures() {
        return Axios.get('Users/GetCultures').then(response => {
            return response.data.model;
        });

    },
    formatFullName(firstName, lastName) {
        return firstName + ' ' + lastName;
    },
	save(user) {
        return Axios.post('Users/SaveUser', user);
    },
    loginCurrentUser(id) {
        return Axios.post('Users/LoginCurrentUser?id=' + id);
    },
    logoutCurrentUser() {
        return Axios.post('Users/LogoutCurrentUser');
    },
    getAllGravatars() {
        return Axios.get('UsersAvatarsGravatar/GetAllGravatars').then(reponse => {
            return reponse.data;
        });
    },
    register(user) {
            return Axios.post('UserRegistration/Register', user).then(response => {
            return response.data;
        });
    },
    autoRegister(userName) {
        return Axios.post('UserRegistration/AutoRegister?userName=' + encodeURIComponent(userName)).then(response => {
            return response.data;
    });
    },
    forgetPassword(email) {
        return Axios.get('Users/LostPassword?email=' + email).then(response => {
            return response.data;
        });
    },
    getAvatarUrl(email) {
        return Axios.get('Users/GetAvatarUrl?email=' + email).then(response => {
            return response.data;
        });
    },
    validateAuthentication(user, password) {
        return Axios.post('Users/ValidateAuthentication', {user, password}).then(response => {
            return response.status === 200;
        });
    },
    getAllSignatories() {
        return ReferentialService.getReferentials('signatoryTitles');
    }
};