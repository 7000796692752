/*global $, MainNotifier */

export default {
    exportName: 'NotificationService',
    key: 'MainNotifier',
    isDefault: false,
    showError(message) {
        $(document).ready(function () {
            MainNotifier.showError(message);
        });
    },
    showConfirmation(message) {
        $(document).ready(function () {
            MainNotifier.showConfirmation(message);
        });
    },
    showWarning(message) {
        $(document).ready(function () {
            MainNotifier.showWarning(message);
        });
    },
    showInformation(message) {
        $(document).ready(function () {
            MainNotifier.showInformation(message);
        });
    }
};
