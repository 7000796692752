import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: `../../`,
    headers: {
        Authorization: {
            toString() {
                return localStorage.getItem('currentUserToken');
            }
        },
        AuthorizationOperator: {
            toString() {
                return localStorage.getItem('operatorToken');
            }
        }
    }
});

const errorListeners = [];

axiosInstance.interceptors.response.use(response  => response, (error) => {

    errorListeners.forEach(l => l(error));

    return Promise.reject(error);
});

axiosInstance.registerErrorListener = (callback) => {
    errorListeners.push(callback);
};


axiosInstance.resolveUrl = (url) => {
    return `${axiosInstance.defaults.baseURL}${url}`;
};

axiosInstance.setBaseUrl = (baseUrl) => {
    axiosInstance.defaults.baseURL = baseUrl;
};

axiosInstance.getFileName = (headers) => {
    var filename = "";
    var disposition = headers['content-disposition'];
    if (disposition) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches !== null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
        }
    }
    return filename;
};

axiosInstance.downloadFile = (response, isOpendFile = false, fileName = null) => {
    if (response.data?.constructor?.name !== "Blob")
        throw new Error("The download file function works only with the files having Blob type.");

    const blobObject = response.data;
    if (fileName === null) {
        fileName = decodeURIComponent(axiosInstance.getFileName(response.headers));
    }

    if (window.navigator && window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(blobObject, fileName);

    } else {
        const url = window.URL.createObjectURL(blobObject);
        const link = document.createElement('a');
        link.href = url;

        if (isOpendFile === false)
            link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    }
};

//Add unauthorized listener
axiosInstance.registerErrorListener((error) => {
    if (error.response.status === 401) {
        localStorage.removeItem('currentUserToken');
    }
});

export default axiosInstance;