export default {
    exportName: 'ErrorHandler',
    key: 'TemplateLegacy',
    async onErrorAsync(error) {
        error.isHandled = true;

        const i18n = (await import('@/Fwamework/Culture/Services/localization-service')).I18n;

        let message = error.response.data?.message ??
            error.response?.data?.title ??
            error.message ??
            error.response.exceptionMessage ?? 
            i18n.t('unknownErrorMessage');

        if (error.response?.statusText) {
            message = `${message} (${error.response.statusText})`;
        }
        const notificationService = (await import('@/Common/Services/notification-service')).default;
        notificationService.showError(message);
    }
};