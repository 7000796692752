
export default {
	async onErrorAsync(error) {
		const ajaxResponse = error?.response;
		if (ajaxResponse) {
			await handleAjaxErrorAsync(ajaxResponse, error);
		}
	}
};

const code400 = 400;
async function handleAjaxErrorAsync(response, error) {
	const i18n = (await import('@/Fwamework/Culture/Services/localization-service')).I18n;

	let message;
	if (response.status == code400 && response.data.message?.includes('[DataTooLong]')) {
		error.isHandled = true;
		message = i18n.t(`[DataTooLong]errorMessage${response.status}`);
	}
	else
		if (response.status == code400 && response.data.message) {
			error.isHandled = true;
			message = response.data.message;
		}
		else 
			if (response.status == 400 && response.request?.responseType == "blob") {
				error.isHandled = true;
				message = i18n.t(`ReadingFileError`);
			}
		
	const notificationService = (await import('@/Common/Services/notification-service')).default;
	notificationService.showError(message);
}