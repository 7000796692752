export default {
    exportName: 'UserFormComponent',
    key: 'GeneralInformation',
    getSections(userContext) {
        return {
            key: 'GeneralInformation',
            component: () => import('@/DerogBioUsers/Components/GeneralInformation.vue'),
            config: {
                title: userContext.data.general.id === 0 ? "" : `${userContext.data.general.firstName} ${userContext.data.general.lastName}`,
                displayPriority: 1
            }
        };
    }
};