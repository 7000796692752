
export default {
	exportName: 'ErrorHandler',
	key: 'DefaultErrorHandler',
	async onErrorAsync(error) {
		error.isHandled = true;
		let message = error.message;
		const ajaxResponse = error?.response;
		if (ajaxResponse) {
			await handleAjaxErrorAsync(ajaxResponse, error.message, error.stack);
		}
		else {
			
			const notificationService = (await import('@/Common/Services/notification-service')).default;
			notificationService.showError(message);
		}
	}
};

const codesWithDefaultMessages = [400, 401, 403, 404];
async function handleAjaxErrorAsync(response, errorMessage, stack) {
	const i18n = (await import('@/Fwamework/Culture/Services/localization-service')).I18n;
	
	let message = getMessageFromResponse(response, errorMessage, i18n);
	if (response.status >= 400 && response.status < 500) {
		const notificationService = (await import('@/Common/Services/notification-service')).default;
		notificationService.showError(message);
	}
	else {
		const errorDetail = getDetailFromResponse(response, stack);
		const router = (await import('@/Common/Services/vue-router-service')).default;

		router.push({
			name: "Error",
			params: { errorTitle: message, errorDetail: errorDetail }
		});
	}
}

function getMessageFromResponse(response, errorMessage, i18n) {

	if (response.statusText)
		return i18n.t(response.statusText);
	else if (codesWithDefaultMessages.includes(response.status)) {
		return i18n.t(`errorMessage${response.status}`);
	} else {
		//NOTE: Error message can be contained in diferent properties depending on error
		let message = response.data?.title ??
			errorMessage ??
			response.data?.message ??
			response.exceptionMessage ??
			i18n.t('unknownErrorMessage');

		return message;
	}
	
}

function getDetailFromResponse(response, stack) {
	let responseMessage = "";
	if (response.data && response.data !== "") {
		const responseData = typeof response.data === "string" ? response.data : JSON.stringify(response.data);
		responseMessage += `Response data: ${responseData} \n\n`;
	}
	if (response.stack) {
		responseMessage += `Response stack: ${response.stack} \n\n`;
	}
	if (stack) {
		responseMessage += `Error stack: ${stack}`;
	}
	return responseMessage;
}