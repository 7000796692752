import FrMessages from 'devextreme/localization/messages/fr.json';
import DataGrid from 'devextreme/ui/data_grid';
import DateBox from "devextreme/ui/date_box";
import Tooltip from "devextreme/ui/tooltip";
import NumberBox from 'devextreme/ui/number_box';
import NumberLocalization from 'devextreme/localization/number';
import TranslationOverrides from '@/Common/Services/devextreme-translation-overrides';
import { loadMessages } from 'devextreme/localization';
import config from "devextreme/core/config";

export default {
    configure() {
        this.applyDefaultRules();

        loadMessages(FrMessages);
        loadMessages(TranslationOverrides);
    },
    applyDefaultRules() {
        config({
            forceIsoDateParsing: true,
        });
        DataGrid.defaultOptions({
            options: {
                wordWrapEnabled: true,
                rowAlternationEnabled: true,
                dateSerializationFormat: "yyyy-MM-ddTHH:mm:ss"
            }
        });
        DateBox.defaultOptions({
            options: {
                dateSerializationFormat: "yyyy-MM-ddTHH:mm:ss",
                displayFormat: {
                    parser: function (value) {

                        let parts = value.split('/');
                        if (3 !== parts.length) {
                            return;
                        }
                        return new Date(parts[2].length < 3 ? Number('20' + parts[2]) : Number(parts[2]), Number(parts[1]) - 1, Number(parts[0]));
                    }
                }
            }
        });
        Tooltip.defaultOptions({
            options: {
                showEvent: { name: 'mouseover', delay: 150 },
                hideEvent: { name: 'mouseout', delay: 150 }
            }
        });
        NumberBox.defaultOptions({
            options: {
                inputAttr: {
                    style: "text-align: right"
                },
                onKeyDown(e) {
                    const currentDecimalSeparator = NumberLocalization.getDecimalSeparator();
                    if (currentDecimalSeparator !== e.event.key && e.event.keyCode === 110) {
                        e.event.preventDefault();
                        e.event.stopPropagation();
                        const input = e.element.querySelector("input.dx-texteditor-input");
                        if (!input.value.includes(currentDecimalSeparator)) {
                            e.component._setInputText(input.value + currentDecimalSeparator);
                            input.setSelectionRange(input.value.length, input.value.length);
                        }
                    }
                }
            }
        });
    }
};
