

import '@/Common/Services/helpers';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import '@/Common/Content/css/grillade-flex.css';
import '@/Common/Content/css/main.css';

import Vue from 'vue';
import LocalizationService, { I18n } from '@/Fwamework/Culture/Services/localization-service';
import QueryUrlHelper from '@/Common/Services/query-url-helper';
const GetVueComponentsRouter = () => import('@/Common/Services/vue-router-service').then(serv => serv.default);
const GetCustomRouter = () => import('@/Common/Services/window-location-router').then(serv => serv.default);
const GetCustomRoutes = () => import('./routes').then(serv => serv.default);
import UserService from '@/Fwamework/Users/Service/user-service';
import Axios from '@/Common/Services/axios';
import ConfigurationService from '@/Fwamework/Core/Services/configuration-service';
import "@/Sentry/sentry";
import NotificationService from '@/Common/Services/notification-service';
import ErrorHandlerService from '@/Common/Services/error-handler-service';
import ExportService from '@/Fwamework/Core/Services/export-provider-service';

import DevExtremeNormalizationService from '@/Common/Services/devextreme-normalization-service';

import DerogBioErrorHandler from '@/Common/Services/derogbio-error-handler';

import VueWait from 'vue-wait';

/**TEMPORARY**/
import { locale } from 'devextreme/localization';
import ErrorsFR from '@/Common/Content/errors-global-messages.fr.json';
import ErrorsEN from '@/Common/Content/errors-global-messages.en.json';

const vueDomContainers = document.getElementsByClassName('vue_container');
if (vueDomContainers.length > 0) {

    try {
        Vue.use(VueWait);




        DevExtremeNormalizationService.configure();
        let allowAnonymous = false;
        for (let vueDomContainer of vueDomContainers) {
            let elDom = vueDomContainer.firstElementChild;

            if (typeof elDom.dataset.baseUrl !== 'undefined') {
                Axios.setBaseUrl(elDom.dataset.baseUrl);
            }
            if (!allowAnonymous && elDom.dataset.allowAnonymous && JSON.parse(elDom.dataset.allowAnonymous))
                allowAnonymous = true;
        }


        const startupApplication = (enableVueRouter) => {
            ConfigurationService.loadConfiguration().then(() => {

                loadVueApplication(enableVueRouter);
            });
        };

        const loadVueApplication = (enableVueRouter) => {
            const dependencies = Promise.all([GetCustomRouter(), GetCustomRoutes(), GetVueComponentsRouter()])
            dependencies.then(([CustomRouter, CustomRoutes, VueComponentsRouter]) => {
                Vue.prototype.$customRouter = { params: [] };
                Vue.prototype.$customRouter = CustomRouter;
                Vue.prototype.$customRouter.routes = CustomRoutes;
                let notParseableDataAttributes = ['componentPath', 'baseUrl', 'allowAnonymous'];

                for (let vueDomContainer of vueDomContainers) {

                    let elDom = vueDomContainer.firstElementChild;

                    let fullDataAttributes = {};
                    notParseableDataAttributes.forEach(key => {

                        fullDataAttributes[key] = elDom.dataset[key];
                    });

                    let vueProps = window[elDom.dataset['vuePropsId']];
                    Object.assign(fullDataAttributes, vueProps);

                    if (fullDataAttributes.useLegacyErrorHandler == true) {
                        const defaultErrorHandlers = ExportService.getExportedValues('ErrorHandler');
                        const templateLegacyErrorHandler = defaultErrorHandlers.find(ns => ns.key === "TemplateLegacy");
                        ErrorHandlerService.registerErrorHandler(templateLegacyErrorHandler);
                    }
                    if (typeof fullDataAttributes.notifierKey !== 'undefined') {
                        NotificationService.setNotifier(fullDataAttributes.notifierKey);
                    }

                    ErrorHandlerService.registerErrorHandler(DerogBioErrorHandler);
                    ErrorHandlerService.configure(Vue.config);
                    const CurrentPage = () => import(`${fullDataAttributes.componentPath}.vue`);
                    const ModalLoading = () => import('@/Common/Components/ModalLoadingComponent.vue');

                    let vueConfig = {
                        el: elDom,
                        router: enableVueRouter ? VueComponentsRouter : undefined,
                        wait: new VueWait(),
                        render: h => {
                            return h('div', [h(CurrentPage, {
                                props: { ...fullDataAttributes }
                            }), h(ModalLoading)]);
                        },
                        created() {
                            let culture = localStorage.getItem('culture');
                            if (!culture || culture === 'null') {
                                culture = I18n.locale;
                            }
                            locale(culture);
                        },
                        async mounted() {
                            this.$customRouter.params = await QueryUrlHelper.getQueryParams();
                        }
                    };

                    LocalizationService.configureAsync(vueConfig).then(() => {
                        I18n.mergeLocaleMessage('fr-FR', ErrorsFR);
                        I18n.mergeLocaleMessage('en-GB', ErrorsEN);
                        LocalizationService.setCurrentLanguage('fr-FR');
                        new Vue(vueConfig);
                    });
                }
            });
        };

        if (allowAnonymous) {
            import('@/Operator/Services/http-authentication').then(serv => {
                serv.default.configure();
                return import('@/Operator/Services/routing-authentication').then(r => {
                    r.default.configure();
                });
            }).then(r => {
                startupApplication(true);
            });
        } else {
            document.addEventListener("DOMContentLoaded", async function () {

                //Load current user information
                let token = await FwaEu.Fwamework.Users.JsonTokenAuthentication.getToken();

                localStorage.setItem('currentUserToken', token.token);
                let culture = await UserService.getCurrentUserCulture();
                if (culture) {
                    I18n.locale = culture;
                }
                startupApplication(false);
            });
        }
    } catch (exception) {

        alert(exception);
        throw exception;
    }
}