import { Configuration } from "@/Fwamework/Core/Services/configuration-service";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import ErrorHandlerService from '@/Common/Services/error-handler-service';
import VueRouterService from '@/Common/Services/vue-router-service.js';

Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: Configuration.sentryDns,
    integrations:
        [
            new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(VueRouterService),
            tracingOrigins: ["localhost", Configuration.svePublicUrl, /^\//],
        })
        ],

});

ErrorHandlerService.onUnhandledError((error) => {
    Sentry.captureException(error);
});

export default Sentry