const LoginOperator = () => import('@/Operator/Components/LoginOperator.vue');
const InfosOperator = () => import('@/Operator/Components/InfosOperator.vue');
const LegalNotice = () => import('@/Operator/Components/LegalNoticeComponent.vue');
const CitySelection = () => import('@/Operator/Components/CitySelection.vue');
const OperatorSelection = () => import('@/Operator/Components/OperatorSelection.vue');
export const VueRoutes = [

    {
        name: 'InfosOperator',
        path: '/InfosOperator',
        component: InfosOperator,
        props: true
    },
    {
        name: 'LegalNotice',
        path: '/LegalNotice',
        component: LegalNotice,
        meta: {
            allowAnonymous: true
        }
    },
    {
        name: 'Login',
        path: '/Login',
        component: LoginOperator,
        meta: {
            allowAnonymous: true
        }
    },
    {
        name: 'CitySelection',
        path: '/CitySelection/:operator',
        component: CitySelection,
        props: true
    },
    {
        name: 'OperatorSelection',
        path: '/OperatorSelection/:operatorData',
        component: OperatorSelection,
        props: true
    }
];