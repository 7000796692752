import ConfigService from '@/Fwamework/Core/Services/configuration-service';

export default {
    exportName: 'UserFormComponent',
    isDefault: true,
    key: 'UserPermissions',
    isEnabled: async () => {
        const config = await ConfigService.loadConfiguration();
        return config.permissionManager.name === 'DefaultPermissionManager';
    },
    getSections(userContext) {
        return {
            key: 'UserPermissions',
            component: () => import('@/Fwamework/Users/Components/UserPermissionsComponent.vue'),
            config: {
                title: userContext.data.userPermissions.title,
                showForAdmin: false
            }
        };
    }
};