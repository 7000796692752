Array.prototype.groupBy = function (keySelector) {
    var arr = this;
    const map = new Map();
    arr.forEach((item) => {
        const key = keySelector(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return Array.from(map.keys()).map(key => ({ key: key, values: map.get(key) }));
};

Array.prototype.last = function () {
    return this[this.length - 1];
};