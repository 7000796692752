import Axios from '@/Common/Services/axios';

const cacheValidityInMilliseconds = 10 * 60 * 1000;
var ReferentialsPromise = null;
var referentialsCacheTime = null;
var referentialsCache = null;

function getReferentialsPromise() {
    if (!ReferentialsPromise) {
        ReferentialsPromise = new Promise(function (resolve) {

            let lastReferentialsLoadTimeRaw = referentialsCacheTime;

            if (!lastReferentialsLoadTimeRaw
                ||
                //Check cache validity
                new Date() - new Date(lastReferentialsLoadTimeRaw) >= cacheValidityInMilliseconds) {

                Axios.get('Referential/GetReferentials').then(response => {
                    referentialsCacheTime = new Date();
                    referentialsCache = response.data.referentials;
                    resolve(referentialsCache);
                });
            }
            else {
                resolve(referentialsCache);
            }
        });
    }
    return ReferentialsPromise;
}

export default {
    async preloadReferentials() {
        await getReferentialsPromise();
    },
    async getReferentials(referentialName) {
        let referentials = await getReferentialsPromise();
        return JSON.parse(JSON.stringify(referentials[referentialName]));
    },
    getArticles() {
        return this.getReferentials('articles');
    },
    getArticle(codeArticle) {
        return this.getReferentials('articles').then(referentials => {
            return referentials.find(ar => ar.code === codeArticle);
        });
    },
    getListOfTitles() {
        return this.getReferentials('contactQualites');
    },
    getListOfRevenues() {
        return this.getReferentials('chiffreAffaires');
    },
    getListOfCategories() {
        return this.getReferentials('typeTroupeaux');
    },
    getListOfSpecies() {
        return this.getReferentials('especeAnimales');
    },
    getListOfSpeciesV2() {
        return this.getReferentials('especeAnimalesV2');
    },
    getListOfVegetalSpecies() {
        return this.getReferentials('vegetalSpecies');
    },
    getListOfMotives() {
        return this.getReferentials('culturePerennesMixites');
    },
    getAnimalAgeCategory() {
        return this.getReferentials('developpementAnimauxes');
    },
    getAnimalGender() {
        return this.getReferentials('genreAnimals');
    },
    getListofMortalityCauses() {
        return this.getReferentials('causeMortaliteAnimauxes');
    },
    getMortalityCauses(causeMortaliteAnimauxId) {
        return this.getReferentials('causeMortaliteAnimauxes').then(referentials => {
            return referentials.find(ar => ar.id === causeMortaliteAnimauxId);
        });
    },
    getListOfForageNature() {
        return this.getReferentials('natureDuFourrages');
    },
    getForageProductionMotives() {
        return this.getReferentials('motifPerteProductionFourrageres');
    },
    getQualitesFourrages() {
        return this.getReferentials('qualitesFourrages');
    },
    getListFodder() {
        return this.getReferentials('fodders');
    },

    getOpinionTypes() {
        return this.getReferentials('avisOCs');
    },
    getObservationOcTypes() {
        return this.getReferentials('observationOCs');
    },
    getListOfTypeAquaculture() {
        return this.getReferentials('typeAquacultures');
    },
    getListOfStadeProductionAquacole() {
        return this.getReferentials('stadeProductionAquacoles');
    },
    getListOfCauseEntraveProductionMiel() {
        return this.getReferentials('causeEntraveProductionMiels');
    },
    getListOfNatureAlimentationBio() {
        return this.getReferentials('natureAlimentationBios');
    },
    getListOfEspeceAquacultures() {
        return this.getReferentials('especeAquacultures');
    },
    getListOfTypeProduitVinification() {
        return this.getReferentials('typeProduitVinifications');
    },
    getListOfCauseDeterioration() {
        return this.getReferentials('causeDeteriorations');
    },

    getCities(skip, take, searchValue) {
        let url = `/City/Cities?skip=${skip}&take=${take}`;
        if (searchValue) {
            url += `&searchValue=${searchValue}`;
        }
        return Axios.get(url).then(response => {
            return response.data;
        });
    },
    getCityByIds(ids) {
        return Axios.post(`/City/GetCityByIds/`, { ids }).then(response => {
            return response.data;
        });
    },
    getCityById(cityId) {
        return Axios.get(`/City/GetCity?id=${cityId}`).then(response => {
            return response.data;
        });
    },
    getListOfExploitationOrientations() {
        return this.getReferentials('exploitationOrientations');
    },
    getCancelRequestInstructorMotives() {
        return this.getReferentials('cancelRequestInstructorMotives');
    },
    getCancelRequestOperatorMotives() {
        return this.getReferentials('cancelRequestOperatorMotives');
    },
    getCancelRequestMotive(id) {
        return this.getReferentials('cancelRequestMotives').then(referentials => {
            return referentials.find(ar => ar.id === id);
        });
    },
    getListOfReasons() {
        return this.getReferentials('raisons');
    },              
    getListOfOperations() {
        return this.getReferentials('operations');
    },
    getListOfPainReducingMethods() {
        return this.getReferentials('methReducSouffs');
    },
    getListOfBreedingTypes() {
        return this.getReferentials('typeElevages');
    },
    getListOfQualifiedPersons() {
        return this.getReferentials('personneQualifies');
    },
    getListOfFunctionIngredients() {
        return this.getReferentials('fonctionIngredients');
    },
    getListOfDuration() {
        return this.getReferentials('durations');
    },
    getListOfDenominationIngredient() {
        return this.getReferentials('denominationIngredients');
    },
    getListOfAgeUnity() {
        return this.getReferentials('uniteAges');
    },
    getListOfAgeUnityV2() {
        return this.getReferentials('uniteAgeV2');
    },
    getListOfProgram() {
        return this.getReferentials('programs');
    }
};
