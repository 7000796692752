import VueRouter from 'vue-router';
import { VueRoutes } from '@/routes';
import Vue from 'vue';

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
	return originalPush.call(this, location).catch(error => {

		if (!VueRouter.isNavigationFailure(error, error.type)) {
			throw error;
		}
	});
}

Vue.use(VueRouter);

var routes = [];
routes = routes.concat(VueRoutes);
const router = new VueRouter({
	routes
});
router.afterEach(() => {
	setTimeout(function () {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});
	}, 0);
});



export default router;
export const RouterLink = Vue.component('RouterLink');