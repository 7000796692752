export default [
    {
        path: 'Fwamework/Users/Users.aspx',
        name: 'Users'
    },
    {
        path: 'Fwamework/Users/UserForm.aspx',
        name: 'UserDetail'
    },
    {
        path: 'Fwamework/Errors/Error.aspx',
        name: 'ErrorASPX'
    },
    {
        path: 'Fwamework/Users/UserDetails/ShowUserDetails.aspx',
        name: 'ShowUserDetails'
    },
    {
        path: 'Fwamework/Users/LostPassword.aspx',
        name: 'LostPassword'
    },
    {
        path: 'Fwamework/Content/InfoIcon.png',
        name: 'ImageIconImport'
    }
];