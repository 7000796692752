/******************** SOME PARTS OF CODE ARE COMMENTED BECAUSE THEY ARE NOT YET COMPATIBLE WITH THIS TEMPLATE VERSION ************************/
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import DefaultNumberFormats from '@/Fwamework/Culture/Content/default-number-formats.json';
import DefaultDateTimeFormats from '@/Fwamework/Culture/Content/default-date-time-formats.json';
import { Configuration } from '@/Fwamework/Core/Services/configuration-service';
/*import LanguageProviderService from '@/Fwamework/Culture/Services/language-providers-service';
import CultureConfigurationProvider from '@/Fwamework/Culture/Services/language-configuration-provider-service';
*/

Vue.use(VueI18n);

const i18n = new VueI18n({
	fallbackLocale: Configuration.defaultLanguageCode,
	messages: "",
	numberFormats: DefaultNumberFormats,
	dateTimeFormats: DefaultDateTimeFormats,
	silentTranslationWarn: true,
	missing: (locale, key, vm, values) => {
		return `[missing:${key}]`
	}
});

export default {
	async configureAsync(vueConfig) {
		vueConfig.i18n = i18n;
	},


	setCurrentLanguage(language) {
		i18n.locale = language;
	},

	getCurrentLanguage() {
		return i18n.locale;
	},
	/*

	async loadCurrentLanguageAsync() {
		let currentLanguageProviders = LanguageProviderService.getAll().sort((a, b) => a.priority - b.priority);
		for (var provider of currentLanguageProviders) {
			let currentLanguage = await provider.getCurrentLanguageAsync();
			if (currentLanguage) {
				this.setCurrentLanguage(currentLanguage);
				break;
			}
		}
	},
	getSupportedLanguagesCode() {
		let languages = Configuration.supportedLanguagesCode;
		return languages.split(',');
	},

	getSupportedLanguages() {
		return CultureConfigurationProvider.getCultureConfiguration().getAllCultures();
	},
	*/

	/**
	 * Lazy load of module global messages for current language
	 * */
	/*
	async getGlobalMessagesAsync() {
		let allModules = (await import('@/Fwamework/Core/Services/module-service')).default.getAllModules();
		const currentLanguage = this.getCurrentLanguage();
		let globalMessagesPromises = [];
		for (let module of allModules) {
			if (module.getGlobalMessagesAsync) {
				globalMessagesPromises = globalMessagesPromises.concat(module.getGlobalMessagesAsync(currentLanguage));
			}
		}
		await this.loadGlobalMessagesAsync(globalMessagesPromises, currentLanguage);
	},

	async loadGlobalMessagesAsync(globalMessagesPromises, locale = null) {
		let loadedMessagesResult = await Promise.all(globalMessagesPromises);
		for (let messagesResult of loadedMessagesResult) {
			i18n.mergeLocaleMessage(locale || i18n.locale, messagesResult.default);
		}
	}
	*/
}

export const I18n = i18n;
