const ErrorPage = () => import('@/Common/Components/UserErrorPageComponent.vue');

export const VueRoutes = [

    {
        name: 'Error',
        path: '/Error',
        component: ErrorPage,
        props: true,
        meta: {
            allowAnonymous: true
        }
    }
];