import ExportService from '@/Fwamework/Core/Services/export-provider-service';

const notifierServices = ExportService.getExportedValues('NotificationService');
let currentNotifier = notifierServices.find(ns => ns.isDefault);

export default {

    setNotifier(notifierKey) {
        currentNotifier = notifierServices.find(ns => ns.key === notifierKey);
    },
    showError(message) {
        currentNotifier.showError(message);
    },
    showConfirmation(message) {
        currentNotifier.showConfirmation(message);
    },
    showWarning(message) {
        currentNotifier.showWarning(message);
    },

    showInformation(message) {
        currentNotifier.showInformation(message);
    }
};
