export default {
    getQueryParams: async function (url) {
        const pageUrl = url || window.location.search.substring(1);
        const urlVariables = pageUrl.split('&');
        let params = {};
        for (var i = 0; i < urlVariables.length; i++) {
            var parameter = urlVariables[i].split('=');
            params[parameter[0]] = decodeURIComponent(parameter[1]);
        }
        return params;
    },
    addArgument: function (uri, key, value) {
        var encodedValue = encodeURIComponent(value);
        var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        var separator = uri.indexOf('?') !== -1 ? "&" : "?";
        if (uri.match(re)) {
            return uri.replace(re, '$1' + key + "=" + encodedValue + '$2');
        }
        else {
            return uri + separator + key + "=" + encodedValue;
        }
    }
};