export default {
    exportName: 'UserFormComponent',
    isDefault: true,
    key: 'UserEntityPermissions',
    getSections(userContext) {
        
        let sections = Object.keys(userContext.data.entityPermissions).map(k => {
            return {
                key: k,
                component: () => import('@/Fwamework/Users/Components/EntityPermissionsComponent.vue'),
                props: {
                    type: k
                },
                config: {
                    showForAdmin: false,
                    title: userContext.data.entityPermissions[k].groupName
                }
            };
        });

        return sections;
    }
};