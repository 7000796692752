import Axios from '@/Common/Services/axios';
import camelCase from 'lodash/camelCase';

let ConfigurationLoader = null;

function getConfigurationPromise() {
    if (!ConfigurationLoader) {
        ConfigurationLoader = new Promise(function (resolve) {
            Axios.get('Core/GetConfiguration').then(response => {
                resolve(response.data);
            });
        })
    }
    return ConfigurationLoader;
}

var Configuration = {};
const keyPrefix = /^VUE_APP_/
const fullVueJsConfiguration = process.env;
Object.keys(fullVueJsConfiguration).forEach(key => {
    if (keyPrefix.test(key)) {
        let appName = camelCase(key.replace(keyPrefix, ''));
        Configuration[appName] = fullVueJsConfiguration[key];
    }
});
const defaultExport = {
    async loadConfiguration() {
        Configuration = { ...Configuration, ... await getConfigurationPromise() };


        return Configuration;
    }

};

export default defaultExport;
export { Configuration };
