const context = require.context("./../../../", true, /\.exported.js$/);
const exports = [];


context.keys().forEach(function (key) {
    exports.push(context(key).default);
});


export default {
    getExportedValues(exportName) {
        return exports.filter(x => x.exportName === exportName);
    }
};