const context = require.context("./", true, /routes\.js$/);

let globalRoutes = [
    {
        path: '',
        name: 'Home'
    }
];
var globalVueRoutes = [{
    name: 'Home',
    path: '/',
    redirect: "/ListDemande"
}];

context.keys()
    .filter(key => key !== "./routes.js")//Exclude current routes.js file
    .forEach(function (key) {
    let exportedModule = context(key);
    let exportedRoutes = exportedModule.default;
    let exportedRequestVueRoutes = exportedModule.VueRoutes;
    if (exportedRoutes) {
        globalRoutes = globalRoutes.concat(exportedRoutes);
    }
    if (exportedRequestVueRoutes) {
        globalVueRoutes = globalVueRoutes.concat(exportedRequestVueRoutes);
    }
});

export const VueRoutes = globalVueRoutes;
export default globalRoutes;